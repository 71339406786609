import { Button } from '@arckit/ui'
import SectionTitle from 'components/global/SectionTitle'
import { Section, Row, Col } from 'components/grid'
import React from 'react'
import DigitalSolutionsProductBox from 'components/core-adoption/builder/DigitalSolutionsProductBox'

interface Props {
	gradeLevel: 'K-5' | '6-12'
	credentials: {
		'K-5': {
			username: string
			password: string
		}
		'6-12': {
			username: string
			password: string
		}
	}
}

export default function DigitalSolutionsAccess({ gradeLevel, credentials }: Props) {
	return (
		<Section margin="mt-32" scrollMarginTop="75px">
			<SectionTitle title="Digital Solutions" text='You can access a demo of SchoolPace and ARC Reads by clicking the "Log in to ARC ID" button below.' />
			<Row className="mt-8">
				<Col>
					<div className="mb-6 flex justify-center">
						<a href={`https://arcid.americanreading.com/authorize?response_type=code&district=888&type=user`} target="_blank" rel="noopener noreferrer">
							<Button label="Click to try ARC's Digital Solutions" size="2xl" />
						</a>
					</div>
					<div className="flex flex-col items-center">
						<h3>Credentials</h3>
						<span className="block text-lg font-semibold">
							Username: <span className="font-normal">{credentials[gradeLevel].username}</span>
						</span>
						<span className="block text-lg font-semibold">
							Password: <span className="font-normal">{credentials[gradeLevel].password}</span>
						</span>
					</div>
				</Col>
			</Row>
			<Row className="mt-8">
				<DigitalSolutionsProductBox
					name="SchoolPace"
					logo="/images/logos/schoolpace.svg"
					text="SchoolPace is an online performance management system that provides student performance data for all stakeholders. Teachers, school administrators, and district administrators can access dashboards, reports, and graphics that show detailed information about reading progress."
				/>

				<DigitalSolutionsProductBox
					name="ARC Reads"
					logo="/images/logos/arc-reads-logo.svg"
					text="ARC Reads is a digital logging solution for ARC Accelerator classrooms that increases student agency while saving teachers' time. It allows users to connect to ARC's extensive book database of more than 70,000 titles, see book recommendations based on reading patterns, automatically add reading progress from ARC Bookshelf, and review and approve all students' steps at once. ARC Reads connects directly to SchoolPace."
				/>
			</Row>
		</Section>
	)
}
